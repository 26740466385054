import { useFeatureFlagEnabled, usePostHog } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC } from "react";
import JournalBadge from "./JournalBadge";
import TrustedBy from "./TrustedBy";
import Typography from "./Typography";
import VideoInput from "./VideoInput";

type HeroProps = {} & ComponentPropsWithoutRef<"section">;

const Hero: FC<HeroProps> = ({ ...rest }) => {
  const posthog = usePostHog();
  const isCountryBlackListed = useFeatureFlagEnabled("is-country-black-list");

  return (
    <section {...rest} className={`${rest.className} mt-8`}>
      {/* <div className="inline-flex items-center justify-center gap-2 bg-slate-200 rounded-full px-4 py-1 mx-auto">
        <Typography variant="caption" className="text-slate-600 font-medium">
          AI powered by
        </Typography>
        <img className="w-16" src="/svg/openAI_logo.svg" alt="OpenAI logo" />
      </div> */}

      <Typography variant="h2" component="h1" className="mt-8">
        Turn videos into <span className="text-purple-600">viral shorts</span>
      </Typography>
      <Typography className="mt-8 text-slate-600" variant="body" component="h2">
        Get ready-to-publish TikToks, Reels, Shorts from YouTube videos in a
        click
      </Typography>

      <VideoInput className="mt-8 w-full shadow-2xl max-w-2xl mx-auto" />

      {!isCountryBlackListed && (
        <Typography variant="caption" className="text-slate-500 mt-4">
          Try for free (No credit card required).
        </Typography>
      )}

      {/* <Typography variant="body2" className="text-slate-600 mt-8">
        Curious about results?{"  "}
        <Link
          className="font-semibold"
          onClick={() => {
            splitbee.track("See Demo Clicked");
            posthog?.capture("See Demo Clicked");
          }}
          href={`${process.env.NEXT_PUBLIC_WEB_URL}/videos/c96439f7-ecbb-4d67-85a8-c3b5f17b2b3d`}
        >
          {`See Demo >`}
        </Link>
      </Typography> */}

      <TrustedBy className="mt-8 items-center" />
      <JournalBadge className="mt-8" />
    </section>
  );
};

export default Hero;
