import ReactPlayer from "react-player";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import FAQ from "../components/FAQ";
import FeaturesSection from "../components/FeaturesSection";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Logo from "../components/Logo";
import Typography from "../components/Typography";
import VideosDemo from "../components/VideosDemo";

import { SupabaseClient } from "@supabase/supabase-js";

interface HomeProps {
  metrics: {
    clips: number;
    users: number;
  };
}

export default function Home(props: HomeProps) {
  return (
    <>
      <main className="min-h-screen mx-auto text-slate-950">
        {/* <HighDemandBanner /> */}
        <Header className="w-full bg-slate-100" />

        <section className="w-full items-center px-3 lg:px-12 flex-1 max-w-7xl text-center mx-auto">
          {/* <Hero /> */}
          {/* {landingHeroVariantKey == "hero-B" ? (
            <HorizontalHero className="w-full" />
          ) : ( */}
          <Hero className="w-full" />

          {/* <HorizontalHero /> */}
          <Typography variant="h3" className="mt-20">
            <span className="text-purple-600">
              <span className="overflow-hidden inline-flex h-full items-center">
                <SwitchTransition>
                  <CSSTransition
                    key={props.metrics.clips}
                    timeout={75}
                    classNames={{
                      enter: "translate-y-full",
                      enterActive: "translate-y-0",
                      exit: "-translate-y-full",
                    }}
                  >
                    <span className="inline-block transition-transform duration-150">
                      {props.metrics.clips}
                    </span>
                  </CSSTransition>
                </SwitchTransition>
              </span>{" "}
              clips generated{" "}
            </span>
            by{" "}
            <span className="overflow-hidden inline-flex h-full items-center">
              <SwitchTransition>
                <CSSTransition
                  key={props.metrics.users}
                  timeout={75}
                  classNames={{
                    enter: "translate-y-full",
                    enterActive: "translate-y-0",
                    exit: "-translate-y-full",
                  }}
                >
                  <span className="inline-block transition-transform duration-150">
                    {props.metrics.users}
                  </span>
                </CSSTransition>
              </SwitchTransition>
            </span>{" "}
            creators!
          </Typography>

          <div className="flex gap-2 md:gap-8 mt-16">
            <div className="rounded-xl isolate md:rounded-2xl overflow-hidden w-full aspect-[9/16]">
              <ReactPlayer
                playsinline
                muted
                playing
                width="100%"
                height="100%"
                loop
                url="https://assets.klap.app/Living%20in%20an%20exciting%20moment%20in%20scientific%20history%20-%20seeking%20out%20new%20life_compressed.mp4"
              />
            </div>
            <div className="rounded-xl isolate md:rounded-2xl overflow-hidden w-full aspect-[9/16]">
              <ReactPlayer
                playsinline
                muted
                playing
                width="100%"
                height="100%"
                loop
                url="https://assets.klap.app/The%20intersection%20between%20music%20and%20fashion_compressed.mp4"
              />
            </div>
            <div className="rounded-xl isolate md:rounded-2xl overflow-hidden w-full aspect-[9/16] bg-slate-200">
              <ReactPlayer
                playsinline
                muted
                playing
                width="100%"
                height="100%"
                loop
                url="https://assets.klap.app/The%20importance%20of%20clear%20call-to-action%20buttons_compressed.mp4"
              />
            </div>
            <div className="hidden md:block isolate rounded-xl md:rounded-2xl overflow-hidden w-full aspect-[9/16] bg-slate-200">
              <ReactPlayer
                playsinline
                muted
                playing
                width="100%"
                height="100%"
                loop
                url="https://assets.klap.app/Expe%CC%81rimentation%20de%20l'uniforme%20ou%20tenue%20unique%20a%CC%80%20l'e%CC%81cole%20(1)_compressed.mp4"
              />
            </div>
          </div>

          {/* <div className="rounded-xl overflow-hidden mt-4 border-slate-200">
              <ReactPlayer
                width="100%"
                height="100%"
                loop
                muted
                playing
                url="/videos/editor-demo.mp4"
              />
            </div> */}

          <FeaturesSection className="my-64" />
          <section className="mx-auto w-full max-w-5xl my-64">
            <Typography variant="body" className="text-slate-600">
              Let AI generate ready-to-publish clips
            </Typography>
            <Typography className="mt-4" variant="h3">
              Reach more people
              <br />
              <span className="text-purple-600">without extra work</span>
            </Typography>
            <div className="flex justify-center items-center gap-2 mt-16">
              <Typography className="text-slate-600">Made on</Typography>
              <Logo />
            </div>
            <VideosDemo className="mt-16 max-w-3xl mx-auto" />
          </section>
          <FAQ className="mx-auto w-full max-w-5xl my-64" />
        </section>
        <Footer />
      </main>
    </>
  );
}

export const getStaticProps = async () => {
  const supabase = new SupabaseClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL as string,
    process.env.SUPABASE_SERVICE_KEY as string
  );

  const getCountForTable = async (table: string) => {
    //  if local used cached value
    if (process.env.NODE_ENV === "development") return 0;

    const { data, count, error } = await supabase
      .from(table as string)
      .select("*", { count: "exact", head: true });
    if (error || !count) return 2499133;
    return count;
  };

  return {
    props: {
      metrics: {
        clips: await getCountForTable("videos_variants"),
        users: await getCountForTable("profiles"),
      },
    },
  };
};
