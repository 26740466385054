import ReactPlayer from "react-player";
import Typography from "./Typography";

interface VideosDemoProps {
  className?: string;
}

const VideosDemo = (props: VideosDemoProps) => {
  return (
    <div className={`${props.className} relative py-[calc(100%*1/9)]`}>
      <div className="flex items-center justify-center">
        <div className="relative w-[calc(100%-1/3*50%)] -ml-[calc(100%*1/6)]">
          <div className="w-full">
            <div className="aspect-[640/360] isolate w-full h-full shadow-2xl rounded-2xl opacity-70 hover:scale-105 hover:z-10 hover:opacity-100 relative transition-all overflow-hidden">
              <ReactPlayer
                id="ytplayer"
                muted
                itemType="text/html"
                width={"100%"}
                height={"100%"}
                url="https://www.youtube.com/watch?v=jNJQTIUyT9Y&t=1s&ab_channel=YCombinator"
              />
            </div>
            <Typography
              className="mt-4 text-slate-600 font-medium"
              variant="caption"
            >
              YouTube video into...
            </Typography>
          </div>
          <div className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/3 w-1/2">
            <div className="shadow-2xl isolate rounded-2xl hover:scale-105 hover:z-10 hover:opacity-100 relative transition-all aspect-[9/16] overflow-hidden">
              <ReactPlayer
                loop
                autoPlay
                playing
                muted
                width={"100%"}
                height={"100%"}
                controls
                url="https://jcgvnoxzvxorflmpxjqv.supabase.co/storage/v1/object/public/videos/d857a6ad-2e43-4cd6-9e7b-f591d7ea8849/9c7038f1-74ea-47c4-aab3-97dffca909dd.mp4"
              />
            </div>
            <Typography
              className="mt-4 text-slate-600 font-medium"
              variant="caption"
            >
              AI-generated TikTok
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosDemo;
